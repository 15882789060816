import TagWordCloud from './TagWordCloud'

const ReportPage = () => {
  return (
    <div className='report-page'>
      <TagWordCloud />
    </div>
  )
}

export default ReportPage
